.passage-page {
  height: calc(100vh - 112px);
}

.paragraph {
  white-space: pre-wrap;
}

.text-area {
  height: calc(100vh - 192px);
}

.question-section {
  height: calc(100vh - 192px);
}
